/*---------------------
	12. Datetimepicker
-----------------------*/

.bootstrap-datetimepicker-widget{
	.timepicker { 
		td {
			a {
				padding: 0;
				span {
					padding: 0;
					&:hover {
						background: $__secondarycolor;
					}
				}
			}
		} 
	}
	.datepicker {
		th, td { 
		    text-align: center;
		    padding: 8px 12px;
		    font-size: 14px;
		}
		td {
			&.active {
				background: $__primarycolor;
				&:hover {
					background: $__secondarycolor;
				}
			}
		}
	}
}